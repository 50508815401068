import type { QueryResult } from '@apollo/client/react';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import type { GridListTypeAndProps } from '@aurora/shared-client/components/common/List';
import { ListItemSpacing, ListVariant } from '@aurora/shared-client/components/common/List/enums';
import { PagerVariant } from '@aurora/shared-client/components/common/Pager/enums';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import type { CoreNodeConnection } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeCardSize } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import React, { useEffect, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import getGridListColumns from '../../../../helpers/util/GridListColumnsHelper';
import { ItemType, NodeViewVariant } from '../../../../types/enums';
import type {
  FeaturedPlacesWidgetQuery,
  FeaturedPlacesWidgetQueryVariables,
  NodeViewFragment
} from '../../../../types/graphql-types';
import PaneledItemList from '../../../common/List/PaneledItemList/PaneledItemList';
import type { ItemViewTypeAndProps } from '../../../entities/types';
import defaultViewCardProps from '../../../nodes/NodeView/NodeViewCard/NodeViewCardDefaultProps';
import defaultViewInlineProps from '../../../nodes/NodeView/NodeViewInline/NodeViewInlineDefaultProps';
import type {
  NodeViewCardProps,
  NodeViewCommonProps,
  NodeViewInlineProps
} from '../../../nodes/NodeView/types';
import useTranslation from '../../../useTranslation';
import FeaturedWidgetPlaceholder from '../../FeaturedWidgetPlaceholder/FeaturedWidgetPlaceholder';
import type { LayoutProps } from '../FeaturedPlacesWidget/types';
import { FeaturedPlacesWidgetListStyle, LeadWithOption } from '../FeaturedPlacesWidget/types';
import useFeaturedPlacesWidget from '../FeaturedPlacesWidget/useFeaturedPlacesWidget';
import {
  isLayoutPropsCardLayout,
  isLayoutPropsListLayout
} from '../FeaturedPlacesWidgetEditor/useFeaturedPlacesWidgetConfiguration';
import { getNodeIconSize } from '../../../../helpers/nodes/NodeCardHelper/NodeCardHelper';

interface Props {
  /**
   * Variables for the FeaturedPlacesWidget query
   */
  placesQueryVariables: FeaturedPlacesWidgetQueryVariables;
  /**
   * Callback function used to lift the query result up to the parent widget
   */
  onPlacesQueryUpdate: (
    queryResult: QueryResult<FeaturedPlacesWidgetQuery, FeaturedPlacesWidgetQueryVariables>
  ) => void;
  /**
   * Callback function that toggles the visibility of the AddFeaturedPlacesModal
   */
  toggleModal: () => void;
  /**
   * The configuration for the PaneledItemList header
   */
  header: { title: string; titleSrOnly: boolean };
  /**
   * Layout selection props
   */
  layoutProps: LayoutProps;
  /**
   * Whether to display the pager or not
   */
  showPager: boolean;
  /**
   * Class name(s) to apply to the featured places node list
   */
  className: string;
}

/**
 * Transforms the settings from the end user widget configuration for use by the NodeView components
 *
 * @param layoutProps the FeaturedPlacesWidget node Layout props
 * @param nodeViewDrawerId the id for the "drawer" overlay showing child nodes
 * @param onDrawerOpen callback function used to update the node id for the drawer
 */
export function getItemViewTypeAndProps(
  layoutProps: LayoutProps,
  nodeViewDrawerId: string,
  onDrawerOpen: (nodeId: string) => void
): ItemViewTypeAndProps<ItemType.NODE, NodeViewVariant> {
  const {
    layoutOptions: {
      useNodeLatestActivityTime,
      useNodeDescription,
      useNodeTopicsCount,
      useUnreadMessagesCount
    },
    descriptionClampLines
  } = layoutProps;

  // props used by all layouts
  const commonProps: NodeViewCommonProps = {
    useNodeLatestActivityTime,
    useNodeDescription,
    useNodeTopicsCount,
    useUnreadMessagesCount,
    descriptionClampLines
  };

  // props used by the List layout
  const inlineProps: NodeViewInlineProps = {
    useLockIcon: isLayoutPropsListLayout(layoutProps)
      ? layoutProps.layoutOptions.useLockIcon
      : true,
    useNodeAvatar: isLayoutPropsListLayout(layoutProps)
      ? layoutProps.leadWithOption === LeadWithOption.AVATAR
      : true,
    nodeIconType: isLayoutPropsListLayout(layoutProps)
      ? layoutProps.leadWithOption
      : LeadWithOption.AVATAR,
    useChildNodes: isLayoutPropsListLayout(layoutProps) && layoutProps.nodeDescendantsPageSize > 0,
    nestedNodesPageSize: isLayoutPropsListLayout(layoutProps)
      ? layoutProps.nodeDescendantsPageSize
      : 0,
    useNeutralLabel: true
  };

  // props used by the Card layout
  const cardProps: NodeViewCardProps = {
    useNodeMembersCount: false,
    useNodeAvatar: isLayoutPropsCardLayout(layoutProps)
      ? layoutProps.layoutOptions.useNodeAvatar
      : true,
    useChildNodes: isLayoutPropsCardLayout(layoutProps)
      ? layoutProps.layoutOptions.useChildNodes
      : true,
    nodeCardSize: isLayoutPropsCardLayout(layoutProps) ? layoutProps.cardSize : NodeCardSize.LG,
    nodeIconSize: isLayoutPropsCardLayout(layoutProps)
      ? getNodeIconSize(layoutProps.cardSize)
      : IconSize.PX_160,
    textAlignment: isLayoutPropsCardLayout(layoutProps)
      ? layoutProps.textAlignment
      : TextAlignment.CENTER,
    nodeViewDrawerId,
    handleNodeViewDrawerAction: (node: NodeViewFragment) => {
      if (node) {
        onDrawerOpen(node.id);
      } else {
        onDrawerOpen('');
      }
    }
  };

  if (isLayoutPropsListLayout(layoutProps)) {
    return {
      type: NodeViewVariant.INLINE,
      props: {
        ...defaultViewInlineProps,
        ...commonProps,
        ...inlineProps
      }
    };
  } else {
    return {
      type: NodeViewVariant.CARD,
      props: {
        ...defaultViewCardProps,
        ...commonProps,
        ...cardProps
      }
    };
  }
}

/**
 * Determines which Panel should be used by the PaneledItemList
 *
 * @param hasPlaces whether the component has any places
 * @param layoutProps the FeaturedPlacesWidget node Layout props
 */
function getPanelType(hasPlaces: boolean, layoutProps: LayoutProps): PanelType {
  if (!hasPlaces) {
    return PanelType.NONE;
  } else if (isLayoutPropsCardLayout(layoutProps)) {
    return PanelType.SPACED;
  } else {
    const ListStyleToPanelTypeMap: Record<FeaturedPlacesWidgetListStyle, PanelType> = {
      [FeaturedPlacesWidgetListStyle.SPACE]: PanelType.STANDARD,
      [FeaturedPlacesWidgetListStyle.DIVIDE]: PanelType.DIVIDER,
      [FeaturedPlacesWidgetListStyle.BORDER]: PanelType.BUBBLE
    };

    return ListStyleToPanelTypeMap[layoutProps.listStyle];
  }
}

/**
 * Renders the list of featured places nodes
 *
 * @author Luisina Santos
 */
const FeaturedPlacesList: React.FC<React.PropsWithChildren<Props>> = ({
  placesQueryVariables,
  onPlacesQueryUpdate,
  toggleModal,
  header,
  layoutProps,
  showPager,
  className
}) => {
  const cx = useClassNameMapper();
  const i18n = useTranslation(EndUserComponent.FEATURED_PLACES_LIST);
  const { formatMessage, loading: textLoading } = i18n;
  const [nodeViewDrawerId, setNodeViewDrawerId] = useState<string>('');

  const { first: pageSize } = placesQueryVariables;

  const placesQueryResult: QueryResult<
    FeaturedPlacesWidgetQuery,
    FeaturedPlacesWidgetQueryVariables
  > = useFeaturedPlacesWidget(placesQueryVariables);

  useEffect(() => {
    onPlacesQueryUpdate(placesQueryResult);
  }, [placesQueryResult, onPlacesQueryUpdate]);

  if (textLoading) {
    return null;
  }

  /**
   * Renders the empty state for the FeaturedPlacesWidget
   */
  const EmptyState: React.FC<React.PropsWithChildren<unknown>> = () => (
    <FeaturedWidgetPlaceholder buttonText={formatMessage('edit')} onClick={toggleModal} />
  );

  const containsPlaces: boolean =
    placesQueryResult?.data?.featuredPlacesWidget?.coreNodes?.edges?.length > 0;

  const { title, titleSrOnly } = header;
  const cardLayoutListVariant: GridListTypeAndProps<NodeViewFragment> = {
    type: ListVariant.GRID,
    props: {
      itemSpacing: ListItemSpacing.LG,
      colProps: getGridListColumns(placesQueryVariables.first)
    }
  };

  return (
    <PaneledItemList<
      NodeViewFragment,
      ItemType.NODE,
      ItemViewTypeAndProps<ItemType.NODE, NodeViewVariant>,
      FeaturedPlacesWidgetQuery,
      FeaturedPlacesWidgetQueryVariables
    >
      queryResult={placesQueryResult}
      itemPath="featuredPlacesWidget.coreNodes"
      onUpdate={(newConnection: CoreNodeConnection): FeaturedPlacesWidgetQuery => {
        return {
          featuredPlacesWidget: {
            ...placesQueryResult.data.featuredPlacesWidget,
            coreNodes: newConnection
          }
        } as unknown as FeaturedPlacesWidgetQuery;
      }}
      pageSize={pageSize}
      pagerVariant={{ type: PagerVariant.LOAD_MORE }}
      type={ItemType.NODE}
      variant={getItemViewTypeAndProps(layoutProps, nodeViewDrawerId, (nodeId: string) =>
        setNodeViewDrawerId(nodeId)
      )}
      useEmpty
      empty={EmptyState}
      panel={getPanelType(containsPlaces, layoutProps)}
      listVariant={isLayoutPropsCardLayout(layoutProps) ? cardLayoutListVariant : undefined}
      header={containsPlaces && title}
      className={cx(className)}
      isHeaderSrOnly={titleSrOnly}
      useFooter={showPager}
      footerClassName={cx({ 'bg-transparent lia-g-mt-5': isLayoutPropsCardLayout(layoutProps) })}
    />
  );
};
export default FeaturedPlacesList;
